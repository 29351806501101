import React,{ useState, useEffect ,useCallback,useMemo,useRef} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { CircularProgress, Paper, FormControl, Button, TextareaAutosize, Checkbox, Tooltip } from '@material-ui/core';
import { Delete, Today, AccessTime, Add, Create, Settings, EventAvailable, Comment, ListAlt } from '@material-ui/icons';
import { useStyles } from '../../css/muiStyle';
import {compactStyle, numberColumn}from '../../css/datatableStyle'
import {NoDataComponent,NotAllowedComponent,AutocompleteS,DatePickerUpd, TelUpd} from "../../js/components"
import { MuiPickersUtilsProvider,KeyboardDatePicker,KeyboardTimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import $ from "jquery"
import "../../css/exploitation.css"
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpCnsEncs from "./modals/exp_cns_encs"
import { AlertCompo } from "../../js/components";
import { Link} from "react-router-dom";
import { mdiAccountGroup, mdiArrowDownBoldCircle, mdiCart, mdiCartCheck, mdiChartLine, mdiSwapVerticalBold } from "@mdi/js";
import Iconn from "@mdi/react";

export default function Exp_cat(props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [tous, setTous] = useState([]);
  const [stkL, setStkL] = useState([]);
  const [simL, setSimL] = useState([]);
  const [prodL, setProdL] = useState([]);
  const [userL, setUserL] = useState([]);
  const [filters] = useState({});
  const [subClient, setSubClient] = useState('');
  const [subClientL, setSubClientL] = useState([]);
  const [clientL, setClientL] = useState([]);
  const [clientL2, setClientL2] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [stk,setStk] = useState('');
  const [sim,setSim] = useState('');
  const [note,setNote] = useState('');
  const [qte,setQte] = useState('');
  const [mtn,setMtn] = useState('');
  const [num,setNum] = useState('');
  const [cns,setCns] = useState('');
  const [rNum,setRNum] = useState('');
  const [rSrc,setRSrc] = useState('');
  const [client,setClient] = useState('');
  const [loading, setLoading] = useState(false);
  const [,setIcon]=useState('');
  const [check,setCheck]=useState([])
  const service='_cso.php'
  const classes=useStyles();
  const [called, setCalled] = useState(false);
  const [noData, setNoData] = useState(false);
  const [doSearch, setDoSearch] = useState(false);
  const [notAllowed, setNotAllowed] = useState(false);
  const [clearRows, setClearRows] = useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [errMessage, setErrMessage] = useState('');
  const [records, setRecords] = useState('')
  const labelText1="Date"
  const labelText2="Client"
  const labelText3="Stock"
  const labelText4="Sim"
  const labelText5="Qte."
  const labelText6="Montant"
  const labelText7="Numero"
  const labelText8="Note"
  const labelText9="Temps"
  const labelText10="Produit"
  const labelText11="Agent"
  const labelText12="Source"
  const labelText13="Client"
  const labelText14="Sous client"
  const lodashClonedeep = require('lodash.clonedeep');
  const [Item, setItem] = useState('');
  const [ItemN, setItemN] = useState('');
  const [selecto, setSelecto] = useState('');

  //les stats utilise pour les stats
  const [transAvg, setTransAvg] = useState(0);
  const [transTdy, setTransTdy] = useState(0);
  const [transYtdy, setTransYtdy] = useState(0);
  const [cnsTdy, setCnsTdy] = useState(0);
  const [cnsYtdy, setCnsYtdy] = useState(0);
  const [nbrCli, setNbrCli] = useState(0);

  const conditionalRowStyle=[
    {
        when: row => Gfunc.datesAreOnSameDay(new Date(),Gfunc.normalDate(row.date))===false,
        style: {
          color: "lightgray",         
        }
    }
  ];
  $('.numberonly').on( "keypress",function (e) {    
    var charCode = (e.which) ? e.which : e.keyCode    
    if (String.fromCharCode(charCode).match(/[^0-9]/g))    
        return false;                        
   });  

   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

 

  //fetch stock
  const fetchStk= useCallback(async(serv="_prd.php", act = "getstocks&mod=AT") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setStkL(response.data.jData);  
      }  
    })
  },[Gfunc,handleAlert])

   //fetch stock handler
   const handleOpenStk = useCallback(async () => {
    if(stkL.length===0)
    fetchStk()
    },[stkL,fetchStk])

     //fetch products
  const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,'',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'',name:'Tous'});
        setProdL(response.data.jData);    
    })
   }
  },[Gfunc,handleAlert])

   //fetch produit handler
const handleOpenProd = useCallback(async () => {
    if(prodL.length===0)
    fetchProd()
    },[prodL,fetchProd])

  //fetch Client
  const fetchClient= useCallback(async(serv="_cli.php", act = "gets") => {
    const response = await Gfunc.axiosPostAction(serv,act,{});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setClientL(response.data.jData);
      }  
    })
  },[Gfunc,handleAlert])

   //fetch client handler
 const handleOpenClient = useCallback(async () => {
    if(clientL.length===0)
    fetchClient()
    },[clientL,fetchClient])

  
  //fetch subClient
  const fetchSubClient= useCallback(async(client) => {
    const response = await Gfunc.axiosPostAction("_cli.php","getsubs",{owner:client});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setSubClientL(response.data.jData);
      }  
    })
  },[Gfunc,handleAlert])


  //fetch Client
  const fetchClient2= useCallback(async(serv="_cli.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        response.data.jData.unshift({id:"",name:"Tous"})
        setClientL2(response.data.jData);
      }  
    })
  },[Gfunc,handleAlert])

   //fetch client handler
 const handleOpenClient2= useCallback(async () => {
    if(clientL.length===0)
    fetchClient2();
    else {
      const dat=lodashClonedeep(clientL)
      dat.unshift({id:"",name:"Tous"})
      setClientL2(dat)}
    },[clientL,fetchClient2,lodashClonedeep])

    //fetch users
const fetchUser= useCallback(async(serv="_usr.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
            setUserL(response.data.jData);    
     })
    }
},[Gfunc,handleAlert])
    
      //fetch utilisateurs handler
const handleOpenUsers = useCallback(async () => {
    if(userL.length===0)
    fetchUser()
    },[userL,fetchUser])

  //fetch Sim
  const fetchSim= useCallback(async(serv="_sim.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setSimL(response.data.jData);  
      }  
    })
  },[Gfunc,handleAlert])

   //fetch sim handler
 const handleOpenSim = useCallback(async () => {
    if(simL.length===0)
    fetchSim()
    },[simL,fetchSim])

 //fetch consomations
  const fetch= useCallback(async() => {
    setCalled(true)
    setLoading(true);
    const response=await Gfunc.axiosGetAction(service,'get&mod=AT')
    if(response.data.err&&response.data.err!=='-1'){
       setNotAllowed(true)
       setErrMessage(response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data!==undefined){
        setData(response.data.jData);
        setTous(response.data.jData);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }    
    })  
  setLoading(false);  
  return response;
  },[Gfunc,service])



 //fetch consomation actuel
  const getConso= useCallback(async() => {
    const response=await Gfunc.axiosGetAction(service,'getconso&mod=AT')
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setCns(response.data.jData); 
      }  
    })  
  return response;
  },[Gfunc,handleAlert])

  useEffect(() => {
      if(!called){
        //fetch();
      getConso()
    }
  }, [called, getConso]);

    //vider les elemets selectionnes
const handleClearRows =useCallback(() => {
  setClearRows(!clearRows)
},[clearRows])

    //handle delete multiple
const handleDeleteItem=useCallback(async(row)=>{
  const ids=[]
  const idss=[] 
    const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
    if(x){
        ids.push(row.DT_RowId.substr(1)); idss.push(row.DT_RowId)
      if(ids.length!==0){ 
        const response=await Gfunc.axiosPostAction(service,"del",{ids:ids})
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          handleAlert(true,'success',"L'élément sélectionné a été supprimer avec succès.")
          idss.map(y=>{
            Gfunc.removeItemsByAtt(data,y,"DT_RowId")
            return true
          })
        })
      }
   }
  handleClearRows();
},[Gfunc,handleClearRows,data,handleAlert])

const handleDeleteItems=useCallback(async()=>{
  const ids=[]
  const idss=[]
  if(check.length!==0){
    const x=window.confirm("Êtes-vous sure de supprimer ces éléments?");
    if(x){
      check.map((item)=>{
        ids.push(item.DT_RowId.substr(1)); idss.push(item.DT_RowId)
        return true;
      }) 
      if(ids.length!==0){ 
        const response=await Gfunc.axiosPostAction(service ,"del",{ids:ids})
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          handleAlert(true,'success','Les éléments sélectionnés étés supprimer avec succès.')
          idss.map(y=>{
            Gfunc.removeItemsByAtt(data,y,"DT_RowId")
            return true
          })
        })
      }
   }
  }else handleAlert(true,'success','Vous devez sélectionner au moins un élément!')
  setCheck([]);
},[Gfunc,check,data,handleAlert])

  //modifier un champs avec un select
const handleSelect=useCallback((row,att)=>{
      setSelecto(row[att])
      setItem(row.DT_RowId)
      setItemN(att)  
   },[])

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
         }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc,handleAlert]);
    
      //handle update note
  const handleOnEditNote=useCallback(async(note,row,att)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setSelecto('')
  },[librery,Gfunc,handleAlert]);

//icon change handler
const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);

const HandleChange=useCallback((e,row)=>{
  if(e.target.checked===true){
    const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId));
    if(ind===-1){check.push(row)}
 }
 else{
     const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId))
     check.splice(ind,1)
 }
},[check])

const columns =useMemo(
    () => [
      {
        width:"24px !important",
        cell: (row) => {
          return (
            <div key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}>
               <Checkbox defaultChecked={Gfunc.getIndex(check,row.DT_RowId,"DT_RowId")>-1} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>
            </div>
           );
         },
      },  
      {
        width:"24px !important",
        cell: (row) => {
          return (
            <Tooltip  title="Historique client">
               <Link className="invisibleLink"  to={{pathname: "/c/ch?c="+/*CryptoJS.AES.encrypt(*/row.client_id/*), "Secret Passphrase")*/+"&cn="+row.client}} target="_blank">
                 <ListAlt fontSize="small"  className={classes.blackIcon}  />
               </Link>
            </Tooltip>
           ); 
         },
      },  
      {
          selector: row=>row.date,
          minWidth:'140px !important',
          minWidth:'125px !important',
          cell:(row)=>{
            return(
                  <DatePickerUpd selecto={selecto} Item={Item} ItemN={ItemN} row={row}
                  name="date" minDate={Gfunc.LastXDay(1)} extra={" > "+row.hour}
                  service={service} action='upd'
                  handleSelect={handleSelect} setSelecto={setSelecto} setItem={setItem} handleAlert={handleAlert}/>
            )
        }
      },
      {
        selector: row=>row.client,
        maxWidth:'160px !important',
        minWidth:'130px !important',
        cell: (row) => {
            return (
              <div className=''>
                  {(row.subcom_id===0)?<AutocompleteS
                  title="Client"
                  service={service} action={'upd'}
                  defaultValue={row.client} row={row} list={clientL} 
                  id={'client_id'} name='client' attr='name'
                  onOpen={handleOpenClient} handleAlert={handleAlert}/>
                  :row.client}
              </div>
             );
           },
      },
      {
        selector: row=>row.subcom,
        minWidth:'150px !important',
        cell: (row) => {
            return (
              <div className=''>
                 <AutocompleteS
                  title="Sous Client"
                  service={service} action={'upd'}
                  defaultValue={row.subcom} row={row} list={subClientL} 
                  id={'subcom_id'} name='subcom' attr='name'
                  onOpen={()=>fetchSubClient(row.client_id)} handleAlert={handleAlert}/>
              </div>
             );
           },
      },
      {
        selector: row=>row.produit,
        maxWidth:'180px !important',
        minWidth:'140px !important',
        cell: (row) => {
          return (
            <Tooltip title="Stock">
              <div> {row.produit}</div>
            </Tooltip>
           );
         },
      },
      {
        selector: row=>row.sim,
        minWidth:'100px !important',
        cell: (row) => {
            return (
              <AutocompleteS
                    title="Sim"
                    service={service} action={'upd'} 
                    defaultValue={row.sim} row={row} list={simL} 
                    id={'sim_id'} name='sim' attr='number' attr2="simCode"
                    onOpen={handleOpenSim} handleAlert={handleAlert}/>
             );
           },
      },
      {
        selector: row=>row.prix,
        minWidth:'100px !important',
        style:numberColumn,
        cell: (row) => {
            return (
              <Tooltip title="Prix">
                <div>{(row.prix)?(row.is_pct)?row.prix+"%":new Intl.NumberFormat().format(row.prix):""} </div>
              </Tooltip>
             );
           },
      },
      {
        selector: row=>row.quantite,
        minWidth:'80px !important',
        style:numberColumn,
        cell: (row) => {
            return (
                <Tooltip title="Qte">
                  <div> {new Intl.NumberFormat().format(row.quantite)} </div>
                </Tooltip>
             );
           },
      },
      {
        selector: row=>row.montant,
        minWidth:'100px !important',
        style:numberColumn,
        cell: (row) => {
            return (
                <Tooltip title="Montant">
                    <div> {new Intl.NumberFormat().format(row.montant)} </div>
                </Tooltip>
             );
           },
      }, 
      {
        selector: row=>row.numero,
        minWidth:'130px !important',
        style:numberColumn,
        cell: (row) => {
            return (
                <TelUpd title="Numero" datein={row.date_add} agentin={row.agent_add}in dateupd={row.date_upd} agentupd={row.agent_upd} service={service} action='upd'
                        row={row} att="numero" value={row.numero} id='DT_RowId' handleAlert={handleAlert}/>
             );
           },
        
      },
    
      {
        selector: row=>row.source, 
        cell: (row) => {
          return (
            <Tooltip title="Source">
              <div> {row.source}</div>
            </Tooltip>
           );
         }, 
      },
      { 
        right:true,
        cell: (row) => {
          return (
            <div onClick={()=>handleDeleteItem(row)} title="Supprimer"><Delete className={classes.redIcon} fontSize="small"/></div>        
           );
         },
      }  
  ],[handleOnEdit,classes,handleSelect,selecto,Item,ItemN,handleOpenClient,clientL,service,Gfunc, HandleChange, check, handleDeleteItem,handleAlert,sim,handleOpenSim,fetchSubClient,subClientL]);
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };   
  const handleTimeChange = (date) => {
    setSelectedTime(date);
  };   

     //filtrer
   const handleFilter=async(filter,e,type)=>{
    filters[filter]={type:type,val:e}

    var dat=tous
    Object.keys(filters).map(function(key, index) {
        if(filters[key].val==="Tous"||filters[key].val==="")
        setData(dat)
        else{
            if(filters[key].type==='input')
            dat=dat.filter((d)=>{if(d[key]!=null) return (d[key].toLowerCase().includes(filters[key].val.toLowerCase())); return null})
            else{
            dat=dat.filter((d)=>{return (d[key]===filters[key].val)})
          }
        setData(dat) 
        }
        return true
    });   
    return true
  }   

  //valider le formulaire
const handleSubmit= async() =>{
    const dat={
        date:Gfunc.formatDate(new Date(selectedDate)),
        time:Gfunc.getClock(selectedTime),
        client_id:client,
        stock_id:stk,
        sim_id:sim,
        quantite:parseInt(qte),
        montant:parseFloat(mtn),
        numero:num,
        note:note,
        subcom_id:subClient,
      }
    const response=await Gfunc.axiosPostAction(service,'add&mod=AT',dat)
        if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
            fetch(1)
            getConso();
        }     
        }) 
    }
  //valider formulaire avec touche clavier 
    const detectTouche= (e)=>{
   
      }
  
      const ExpanableComponent = ({ data }) => {
        const [noteB,setNoteB] = useState('');

        return (
          <div>
          <Paper className='consoPaper paperGrid2 '>
            <Paper className='consoExpandPaper item2' style={{height:"80px"}}>
               <div className="flex-wrapper" title="Agent d'ajout">
                  <Create className={classes.blackTinyIcon}/>
                  <div id='label'> {data.agent_add}</div>
               </div>
               <div className="flex-wrapper" title="Date d'ajout">
                  <EventAvailable className={classes.blackTinyIcon}/>
                  <div id='label'> {data.date_add}</div>
               </div>
            </Paper >
             <Paper className='consoExpandPaper item3' style={{height:"80px"}}>
               <div className="flex-wrapper" title="Agent d'édition">
                  <Settings className={classes.blackTinyIcon}/>
                  <div id='label'> {data.agent_upd}</div>
               </div>
               <div className="flex-wrapper" title="Date d'édition">
                  <EventAvailable className={classes.blackTinyIcon}/>
                  <div id='label'> {data.date_upd}</div>
               </div>
            </Paper>
            <Paper className='consoExpandPaperNote item4' style={{height:"80px"}}>
               <div className="flex-wrapper" title="Note" style={{height:"70px"}}>
                  <Comment className={classes.blackTinyIcon}/>  
              {/* (data.note===selecto && data.DT_RowId===Item )?*/}                 
           <TextareaAutosize  className={'textNoteC'} onChange={(e)=>{setNoteB(e.target.value);}} minRows={4} value={(noteB?noteB:data.note)} onBlur={e=>handleOnEditNote(noteB,data,"note")}/>
           {/*<div id='label' onClick={()=>{setSelecto(data.note);setItem(data.DT_RowId);setNoteB(data.note)}}>{data.note}</div>*/}
            </div>
            </Paper>
            </Paper>
          </div>
        )
    }
    
//code jQuery pour boucler le focus des elements
$('form').each(function(){
  var list  = $(this).find('input*[tabindex],#last*[tabindex]').sort(function(a,b){ return a.tabIndex < b.tabIndex ? -1 : 1; }),
      first = list.first();
  list.last().on('keydown', function(e){
      if( e.keyCode === 9 ) {
          first.focus();
          return false;
      }
  });
});


  useEffect(() => {
    if (data.length > 0)
      calcStats(data)
  }, [data])


  const calcStats = (arr) => {
    const today = new Date();
    let nbrClient = 0;
    let moyTrans = 0;
    let nbrTransToday = 0;
    let nbrTransYesterday = 0;
    let cnsToday = 0;
    let cnsYesterday = 0;

    arr.forEach(object => {
      const date = Gfunc.normalDate(object.date);
      if (Gfunc.datesAreOnSameDay(today, date)) {
        nbrTransToday++;
        cnsToday += object.montant;
      } else {
        nbrTransYesterday++;
        cnsYesterday += object.montant;
      }
    });
    nbrClient = Gfunc.countTableId(arr, 'client_id')

    moyTrans = nbrTransToday / ((nbrClient > 0) ? nbrClient : 1)
    setTransAvg(moyTrans)
    setTransTdy(nbrTransToday)
    setTransYtdy(nbrTransYesterday)
    setCnsTdy(cnsToday)
    setCnsYtdy(cnsYesterday)
    setNbrCli(nbrClient)
    return {
      moyTrans,
      nbrTransToday,
      nbrTransYesterday,
      cnsToday,
      cnsYesterday
    };
  };



  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Paper id="pcns" className={classes.paperBody} elevation={5}>
        <div className="table-header displayedDataGrid">
          <div className="staticsHeadWIconDouble"><Iconn path={mdiSwapVerticalBold} color="black" size="24px" /><div className="staticsHead"><h2>{Intl.NumberFormat().format(transTdy)}</h2><p>Transactions du jour</p></div><div className="staticsHead"><h2>{Intl.NumberFormat().format(transYtdy)}</h2><p>Transactions antérieures</p></div></div>
          <div className="staticsHeadWIcon"><Iconn path={mdiChartLine} color="black" size="24px" /><div className="staticsHead"><h2>{Gfunc.formatNumberX(transAvg, 2)}</h2><p>Moyenne des transactions</p></div></div>
          <div className="staticsHeadWIcon"><Iconn path={mdiCart} color="black" size="24px" /><div className="staticsHead"><h2>{Intl.NumberFormat().format(cns)}</h2><p>Consommation actuelle</p></div></div>
          <div className="staticsHeadWIcon"><Iconn path={mdiAccountGroup} color="black" size="24px" /><div className="staticsHead"><h2>{Intl.NumberFormat().format(nbrCli)}</h2><p>Nombre de clients</p></div></div>
          <div className="staticsHeadWIconDouble"><Iconn path={mdiCartCheck} color="black" size="24px" /><div className="staticsHead"><h2>{Intl.NumberFormat().format(cnsTdy)}</h2><p>Consommation du jour</p></div><div className="staticsHead"><h2>{Intl.NumberFormat().format(cnsYtdy)}</h2><p>Consommation antérieure</p></div></div>
        </div>
        <div className="rowGrid" id="row-head">
        <ExpCnsEncs getConso={getConso} param={param}/>           
          </div>
        <div className="formConso smallSelect">
            <ValidatorForm noValidate id="addCns" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
                <div className="rowGrid" id="row-1">
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                margin='dense'
                                size="small"
                                className={classes.diagFormText}
                                inputProps={{tabIndex: "1",className:classes.FormInput}}
                                KeyboardButtonProps={{style:{padding:"0px"}}}
                                inputVariant="outlined"
                                variant="inline"
                                maxDate={new Date()}  
                                invalidDateMessage="Le format de la date est incorrect"   
                                maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                                format="dd/MM/yyyy"
                                name="iDate"
                                id="iDate"
                                orientation='landscape'
                                placeholder={labelText1}
                                keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                                value={selectedDate}
                                onChange={handleDateChange} />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    showTodayButton={true}
                                    className={classes.diagFormText}
                                    inputProps={{tabIndex: "2",className:classes.FormInput}}
                                    KeyboardButtonProps={{style:{padding:"0px",height:'30px'}}}
                                    okLabel="Confirmer"
                                    cancelLabel='Annuler'
                                    todayLabel="Maintenant"
                                    invalidDateMessage="Format est incorrect"   
                                    margin='dense'
                                    size="small"
                                    autoOk={true}
                                    orientation='landscape'
                                    inputVariant="outlined"
                                    ampm={false}
                                    maxDate={new Date()} 
                                    format="HH:mm"
                                    id="iTime"
                                    placeholder={labelText9}
                                    value={selectedTime}
                                    keyboardIcon={<AccessTime className={classes.blackSmallIcon}/>}
                                    onChange={handleTimeChange} />            
                    </MuiPickersUtilsProvider>  
                    <Autocomplete
                            disableClearable
                            defaultValue={labelText2}
                            onChange={(e,newValue) =>{
                              const cid=clientL[Gfunc.getIndex(clientL,newValue,"name")].id
                              setClient(cid)
                              setSubClient(null)
                              setSubClientL([])
                              fetchSubClient(cid)
                            }}
                            onFocus={handleOpenClient}
                            options={clientL.map((option) => option.name)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            className={classes.diagFormText}
                            inputprops={{className:classes.FormInput}}
                            id='idClient'
                            renderInput={(params) => (
                                 <TextValidator {...params} inputProps={{...params.inputProps,tabIndex:'3'}} value={(client)?clientL.find(x => x.id ===client).name:null} color="primary" variant="outlined" size="small" validators={['required']}
                                  errormessages={['']}/>
                        )}/> 
                        <Autocomplete
                            key={client}
                            disableClearable
                            defaultValue={labelText14}
                            onChange={(e,newValue) =>{
                              const cid=subClientL[Gfunc.getIndex(subClientL,newValue,"name")].id
                              setSubClient(cid)
                            }}
                            onFocus={handleOpenClient}
                            options={subClientL.map((option) => option.name)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            className={classes.diagFormText}
                            inputprops={{className:classes.FormInput}}
                            id="isubClient"
                            renderInput={(params) => (
                                 <TextValidator {...params} inputProps={{...params.inputProps,tabIndex:'3'}} value={(subClient)?subClientL.find(x => x.id ===subClient).name:null} color="primary" variant="outlined" size="small" 
                                  errormessages={['']}/>
                        )}/> 
                        <Autocomplete
                            disableClearable
                            defaultValue={labelText3}
                            onChange={(e,newValue) =>setStk(stkL[Gfunc.getIndex(stkL,newValue,"name")].id)}
                            onFocus={handleOpenStk}
                            options={stkL.map((option) => option.name)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            className={classes.diagFormText}
                            id='Stk'
                            inputprops={{className:classes.FormInput}}
                            renderInput={(params) => (
                                <TextValidator  {...params} value={(stk)?stkL.find(x => x.id ===stk).name:null} color="primary" variant="outlined" size="small" validators={['required']}
                                inputProps={{...params.inputProps,tabIndex:'4'}} errormessages={['']}/>
                        )}/>      
                        <Autocomplete
                            disableClearable
                            defaultValue={labelText4}
                            onChange={(e,newValue) =>setSim(simL[Gfunc.getIndex(simL,newValue,"simCode")].id)}
                            onFocus={handleOpenSim}
                            options={simL.map((option) => option.simCode)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            className={classes.diagFormText}
                            id="idSim"
                            inputprops={{className:classes.FormInput}}
                            renderInput={(params) => (
                                <TextValidator {...params} value={(sim)?simL.find(x => x.id ===sim).simCode:null} color="primary" variant="outlined" size="small" 
                                inputProps={{...params.inputProps,tabIndex:'5'}}  errormessages={['']}/>
                        )}/>  
                        <TextValidator margin="dense"  placeholder={labelText5} size="small" type='number' value={qte} variant="outlined"
                            className={classes.diagFormText}
                            id="iQte"
                            inputProps= { {min: 1 ,tabIndex: "6",className:classes.FormInput}}
                          //  InputProps={{ inputProps: { min: 1 ,tabIndex: "6",className:classes.FormInput} }}
                            InputLabelProps={{className:(qte!=='')?undefined:classes.diagFormLabel}}
                            onChange={e =>setQte(e.target.value)} 
                            validators={['required']} 
                            errorMessages={['']} /> 
                        <TextValidator margin="dense"  placeholder={labelText6} size="small" type='number' value={mtn} variant="outlined"
                            className={classes.diagFormText}
                            id="iMtn"
                            inputProps= {{ min: 0 ,step:"0.01",tabIndex: "7",className:classes.FormInput}}
                            //InputProps={{ inputProps: { min: 0 ,step:"0.01",tabIndex: "7",className:classes.FormInput} }}
                            InputLabelProps={{className:(mtn!=='')?undefined:classes.diagFormLabel}}
                            onChange={e =>setMtn(e.target.value)} 
                            validators={['required']} 
                            errorMessages={['']}/> 
                        <TextValidator margin="dense"  placeholder={labelText7} size="small" type='number' value={num} variant="outlined"
                            className={classes.diagFormText}
                            id="iNum"
                            inputProps={{ pattern: "[0][5-7][4-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]",maxLength:10, tabIndex: "8",className:classes.FormInput }} 
                            onChange={e =>setNum(e.target.value)} 
                            errorMessages={['']}/> 
                        <TextValidator margin="dense"  placeholder={labelText8} size="small" value={note} variant="outlined"
                            className={classes.diagFormText}
                            id="iNote"
                            inputProps={{tabIndex: "9",className:classes.FormInput}}
                            onChange={e =>setNote(e.target.value)} 
                            multiline
                            maxRows={1}
                            errorMessages={['']}/>
                    <Button className={classes.diagTabBtn} id='last' type='submit' variant="contained" tabIndex='10' color="primary" startIcon={<Add/>}>Ajouter</Button>
            </div>
            </ValidatorForm>
        </div>
        <div className="dataContainer">
        {(data.length!==0||doSearch)?
            <DataTable 
              expandableRows 
              expandableRowsComponent={ExpanableComponent} 
              ignoreRowClick
              clearSelectedRows={clearRows}
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              noHeader={true}
              noTableHead={true}
              highlightOnHover
              customStyles={compactStyle}
              conditionalRowStyles = { conditionalRowStyle }
            /> : (notAllowed) ? <NotAllowedComponent message={errMessage} /> : (noData || records === 0) ? <NoDataComponent message="Aucun résultat trouvé" /> : <div className="uploadData"><div> <Iconn onClick={fetch} path={mdiArrowDownBoldCircle} color="grey" size="80px" /><p>Charger les données</p></div></div>}
            </div>
       
        <div className='rowGrid filterContainer smallSelect' id='consoFiltre'>
           { /*<Typography> Filtrer par:</Typography>*/}
            <div className="grid-wrapper-field">
              <label id="consoLabel"> {labelText10}</label>    
              <Autocomplete
                              disableClearable
                              defaultValue={labelText10}
                              onChange={(e,newValue) =>{
                                  //setFProd(newValue)
                                  handleFilter('produit',newValue)
                              }}
                              onFocus={handleOpenProd}
                              options={prodL.map((option) => option.name)}
                              getOptionSelected={(option, value) => option.id === value.id}
                              className={classes.diagFormText}
                              inputprops={{className:classes.FormInput}}
                              renderInput={(params) => (
                                  <TextField {...params}  color="primary" variant="outlined" size="small" errormessages={['']}/>
                          )}/>  
            </div>
            <div className="grid-wrapper-field">
              <label id="consoLabel"> {labelText13}</label>    
              <Autocomplete
                              disableClearable
                              defaultValue={labelText13}
                              onChange={(e,newValue) =>{
                                  //setFProd(newValue)
                                  handleFilter('client',newValue)
                              }}
                              onFocus={handleOpenClient2}
                              options={clientL2.map((option) => option.name)}
                              getOptionSelected={(option, value) => option.id === value.id}
                              className={classes.diagFormText}
                              inputprops={{className:classes.FormInput}}
                              renderInput={(params) => (
                                  <TextField {...params}  color="primary" variant="outlined" size="small" errormessages={['']}/>
                          )}/>  
            </div>
            <div className="grid-wrapper-field">
              <label id="consoLabel"> {labelText11}</label>
              <Autocomplete
                              disableClearable
                              defaultValue={labelText11}
                              onChange={(e,newValue) =>{
                                  //setFAgent(newValue)
                                  handleFilter('agent_add',newValue)
                              }}
                              onFocus={handleOpenUsers}
                              options={userL.map((option) => option.name)}
                              getOptionSelected={(option, value) => option.id === value.id}
                              className={classes.diagFormText}
                              inputprops={{className:classes.FormInput}}
                              renderInput={(params) => (
                                  <TextField {...params} color="primary" variant="outlined" size="small" errormessages={['']}/>
                          )}/>  
            </div>
            <div className="grid-wrapper-field">
            <label id="consoLabel"> {labelText4}</label>
            <Autocomplete
                            disableClearable
                            defaultValue={labelText4}
                            onChange={(e,newValue) =>{
                                //setFSim(newValue)
                                handleFilter('sim',newValue)}}
                            onFocus={handleOpenSim}
                            options={simL.map((option) => option.number)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            className={classes.diagFormText}
                            inputprops={{className:classes.FormInput}}
                            renderInput={(params) => (
                                <TextField {...params} color="primary" variant="outlined" size="small" errormessages={['']}/>
                        )}/>
            </div>     
            <div className="grid-wrapper-field">
              <label id="consoLabel"> {labelText7}</label>
            <FormControl >
                <TextField margin="dense"  placeholder={labelText7} size="small" value={rNum} variant="outlined"
                                className={classes.diagFormText}
                                id="iNum"
                                inputProps={{className:classes.FormInput}}
                                onChange={e =>{handleFilter('numero',e.target.value,'input');setRNum(e.target.value)}} />
            </FormControl>
            </div>
            <div className="grid-wrapper-field">
              <label id="consoLabel"> {labelText12}</label>
            <FormControl >
                <TextField margin="dense"  placeholder={labelText12} size="small" value={rSrc} variant="outlined"
                                className={classes.diagFormText}
                                id="iSrc"
                                inputProps={{className:classes.FormInput}}
                                onChange={e =>{handleFilter('source',e.target.value,'input');setRSrc(e.target.value)}} />
            </FormControl>
            </div>
            <Button className={classes.diagTabBtn} onClick={()=>handleDeleteItems()} variant="contained" color="primary" startIcon={<Delete/>}>Supprimer</Button> 
        </div>
      </Paper>
    </div>    
  );
}